import React, { useState, useEffect, useRef } from 'react';
import FadeLink from './fadeLink';

/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
function useOutsideAlerter(ref, onClickOutside) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function NavBar({ }) {
    const [menuActive, setMenuActive] = useState(false)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setMenuActive(false)
    });

    return (
        <div className='w-full'>
            <nav className=" px-8 sm:px-16 py-8 rounded bg-gradient-to-t bg-blend-multiply from-transparent to-black/80 z-50 fixed w-full flex flex-row">
                <div className="container flex flex-wrap justify-between items-center mx-auto">
                    <FadeLink to="/" className="flex items-center">
                        {menuActive ? null :
                            <span className="self-center text-xl text-white font-semibold whitespace-nowrap dark:text-white">Davit G</span>
                        }
                    </FadeLink>

                    <div className="hidden w-full md:block md:w-auto font-sans" id="navbar-default">
                        <ul className="flex flex-col p-4 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                            <NavBarLink to="/" name={"Home"} />
                            <NavBarLink to="/projects" name={"Projects"} />
                            <NavBarLink to="/skills" name={"Skills"} />
                            <NavBarLink to="/contact" name={"Contact"} />
                            <NavBarLink to="/about" name={"About"} />
                        </ul>
                    </div>
                </div>
                <button ref={wrapperRef} onClick={() => setMenuActive(state => !state)} data-collapse-toggle="navbar-default" type="button" className="inline-flex mx-auto items-center text-sm text-gray-500 rounded-lg md:hidden " aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only font-sans">Open main menu</span>
                    {menuActive ? <><ul className='text-right md:text-sm md:font-medium'>
                        <NavBarLink to="/" name={"Home"} />
                        <NavBarLink to="/projects" name={"Projects"} />
                        <NavBarLink to="/skills" name={"Skills"} />
                        <NavBarLink to="/contact" name={"Contact"} />
                        <NavBarLink to="/about" name={"About"} />
                    </ul>
                    </> : <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>}
                </button>
            </nav>

        </div>);
}

export default NavBar;

function NavBarLink({ to, name }) {
    return <li>
        <FadeLink to={to} className="block shadowed py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:text-white md:p-0 dark:text-white" aria-current="page">
            {name}
        </FadeLink>
    </li>;
}
